import React from 'react';
import logo from './logo.svg';
import './App.css';

import './i18n'; 
import { useTranslation } from 'react-i18next';

import PageTitle from './modules/title.module';
import Header from './modules/header.module';
import Footer from './modules/footer.module';
import Banner from './modules/banner.module';
import InfoPanel from './modules/infoPanel.module';
import Program from './modules/program.module';
import Newsletter from './modules/newsletter.module';
import Partners from './modules/partners.module';

function App() {

  const { t } = useTranslation();
  const currentTitle = t('pageTitle'); 
  const currentDescription = t('pageDescription');

  return (
    <>
      <PageTitle title={currentTitle} description={currentDescription} />
      <Banner />
      <div className="container-fluid design-bar"></div>
      <InfoPanel />
      <Partners />
      <div className="container-fluid design-bar"></div>
      <Program />
      {/* <Newsletter /> */}
    </>
  );
}

export default App;
