import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi) // Use the HttpApi
  .use(initReactI18next) // Initialize react-i18next
  .init({
    supportedLngs: ['en', 'hu'], // Define supported languages
    fallbackLng: 'en', // Define a default language
    debug: false, // Set to true to see logs
    // Load translation files from /public/locales/{lng}/{ns}.json
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
