import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../css/header.css';
import LanguageSelector from './languageSelector';
import { ReactComponent as MeetupIcon } from '../img/meetup-icon.svg';
import Logo from '../img/bbccw-logo.png';

const Header = () => {

    const { t } = useTranslation();

    return (
        <header>
            <div className="container h-100">
                <div className="row h-100">
                    <div className="logo col-sm-12 col-lg-3 d-flex justify-content-center align-items-center">
                        <img src={Logo} alt={t('pageTitle')} /> 
                    </div>
                    <div className="links col-sm-12 col-lg-6 d-flex justify-content-center align-items-center">
                        <ul>
                            <li><a href="#info-panel">{t('about')}</a></li>
                            <li><a href="#program">{t('programs')}</a></li>
                            {/* <li><a href="#partners">{t('partners')}</a></li> */}
                        </ul>
                    </div>
                    <div className="social-media-icons col-sm-12 col-lg-2 d-flex justify-content-center align-items-center">
                        <ul>
                            {/* <li><a href="" target="_blank"><MeetupIcon /></a></li> */}
                            <li><a href="https://www.linkedin.com/company/budapest-blockchain-community-week-2024" target="_blank"><span className="menu-icon bi-linkedin"></span></a></li>
                            <li><a href="https://x.com/BudBlockWeek" target="_blank"><span className="menu-icon bi-twitter-x"></span></a></li>
                        </ul>
                    </div>
                    <div className="language-selector col-sm-12 col-lg-1 d-flex justify-content-center align-items-center">
                        <LanguageSelector />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;