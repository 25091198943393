import React, { useEffect } from 'react';

const PageTitle = ({ title, description }) => {
  useEffect(() => {
    document.title = title;
    document.description = description;
  }, [title]);

  return null;
};

export default PageTitle;