import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import '../css/program.css';

import NoProgram from './noProgram.module';
import Footer from './footer.module';

import Programs from '../data/programs.json'

const Program = () => {

    const [activeTab, setActiveTab] = useState(18);

    const { t } = useTranslation();

    const setActiveProgramTab = (id) => {
        setActiveTab(id);
    }

    const buttons = [{id: 18, text: 'MON 18'}, {id: 19, text: 'TUE 19'}, {id: 20, text: 'WED 20'}, {id: 21, text: 'THU 21'}, {id: 22, text: 'FRI 22'}, {id: 23, text: 'SAT 23'}, {id: 24, text: 'SUN 24'}]
    const programs = Programs;

    return (
        <div id="program" className="container-fluid program">
            <div className="container-fluid program-texts">
                <div className="row">
                    <div className="col-sm-12">
                        <h2>{t('programs')}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 program-bullets d-flex justify-content-center">
                        <ul>
                            {buttons.map((button, index) => {
                                return (
                                    <li key={index}>
                                        <button className={ (activeTab == button.id ? 'active' : '') + ' button-white'} onClick={() => setActiveProgramTab(button.id)}>
                                            <div className="button-white-inner">
                                                {button.text}
                                            </div>
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container daily-program-holder">
                <div className="row daily-programs">
            
                    {programs.map((item, index) => {
                        return(
                        <div className="col-sm-12 daily-program" style={ activeTab == item.id ? {display:'block'} : {display:'none'}}>

                        { item.items.length > 0 ? (
                            <>
                            {item.items.map((program, index) => {
                                return(
                                <div className="row program-item">
                                    {program.image ? (
                                    <div className="col-sm-12 col-lg-3 img-holder">
                                        {program.image.includes('https') ? (
                                            <img src={program.image} alt={program.title} />
                                        ) : (
                                            <img src={ '/img/' + program.image} alt={program.title} />
                                        )}
                                    </div>
                                    ) : (<></>) }
                                    <div className={'col-sm-12 ' + (program.image ? ('col-lg-9 ') : ('col-lg-12 ')) + 'content-holder'}>
                                        <h3 className="program-item-name">{program.title}</h3>
                                        <p>{program.desc}</p>
                                        <div className="program-item-date">{program.date}</div>
                                        <div className="program-item-time">{program.time}</div>
                                        <div className="program-item-place">
                                            { program.location ? (
                                                <a href={program.location_link} target="_blank">{program.location}</a>
                                            ) : (<>{t('location-not-disclosed')}</>)}
                                        </div>
                                        <a class="button button-black register-btn" href={program.registration} target="_blank"><span className="text">{t('register')}</span></a>
                                    </div>
                                </div>
                                )
                            })}
                            </>
                        ) : (<NoProgram />) }
                        </div>
                        )
                    })}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Program;