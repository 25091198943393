import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/banner.css';

import Header from './header.module';

const Banner = () => {

    const { t } = useTranslation();

    return (
        <div className="container-fluid banner">
            <Header />
            <div className="container banner-inner">
                <div className="row">
                    <div className="banner-text col-sm-12">
                        <span>{t('welcome')}</span>
                        <h1>{t('headline')}</h1>
                        <h2>{t('place')} {t('event-time')}</h2>
                    </div>
                    <div className="cta col-sm-12">
                        <ul>
                            <li><a className="button button-purp" href="#program">{t('ctaButton1')}</a></li>
                            <li><a className="button button-white" target="_blank" href="https://docs.google.com/forms/d/1dRAYv_GGKh5YvzYA0zIk0f43QS6K-SAYchYDn9-Z0Js">
                                <div className="button-white-inner">{t('ctaButton2')}</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> 
        </div>
    );
};

export default Banner;