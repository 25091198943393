import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/infoPanel.css';

import { ReactComponent as BtcIcon } from '../img/bitcoin-logo.svg';
import { ReactComponent as EthIcon } from '../img/eth-logo.svg';
import { ReactComponent as HlIcon } from '../img/hyperledger-logo.svg';
import { ReactComponent as DotIcon } from '../img/dot-logo.svg';

import BP from '../img/bbccw-bp.png';

const InfoPanel = () => {

    const { t } = useTranslation();

    return (
        <>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 col-lg-12 chain-links d-flex justify-content-center">
                    <ul>
                        <li>
                            <a className="button button-white" href="https://bitcoin.org/hu/" target="_blank">
                                <div className="button-white-inner">
                                    <BtcIcon className="crypto-icon" />{t('Bitcoin')}
                                </div>
                            </a>
                        </li>
                        <li>
                            <a className="button button-white" href="https://ethereum.org" target="_blank">
                                <div className="button-white-inner">
                                    <EthIcon className="crypto-icon" />{t('Ethereum')}
                                </div>
                            </a>
                        </li>
                        <li>
                            <a className="button button-white" href="https://hyperledger.org" target="_blank">
                                <div className="button-white-inner">
                                    <HlIcon className="crypto-icon" />{t('Hyperledger')}
                                </div>
                            </a>
                        </li>
                        <li>
                            <a className="button button-white" href="https://polkadot.network/" target="_blank">
                                <div className="button-white-inner">
                                    <DotIcon className="crypto-icon" />{t('Polkadot')}
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="info-panel" className="container-fluid info-panel">
            <div className="container">
                <div className="row">
                    <div className="col-sm-5">
                    </div>
                    <div className="col-sm-7">
                        <h2>{t('expl-header')}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <img className="bu-da-pest" src={BP} alt="Budapest" />
                    </div>
                    <div className="col-sm-1">
                    </div>
                    <div className="col-sm-6">
                        <p>{t('pageDescription-1')}</p>
                        <p>{t('pageDescription-2')}</p>
                        <p>{t('pageDescription-3')}</p>
                        <p>{t('pageDescription-4')}</p>
                    </div>
                    <div className="col-sm-1">
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default InfoPanel;