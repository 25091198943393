import React from 'react';
import '../css/languageselector.css';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EnFlag } from '../img/en-flag.svg';
import { ReactComponent as HuFlag } from '../img/hu-flag.svg';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="language-selector">
      {i18n.language !== 'en' && (
        <button onClick={() => changeLanguage('en')}>
          <EnFlag />
        </button>
      )}
      {i18n.language !== 'hu' && (
        <button onClick={() => changeLanguage('hu')}>
          <HuFlag />
        </button>
      )}
    </div>
  );
};

export default LanguageSelector;