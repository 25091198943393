import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import '../css/newsletter.css';
import Logo from '../img/bbccw-logo.png';

const NoProgram = () => {

    const { t } = useTranslation();

    return (
        <div className="row no-program">
            <div className="col-sm-12 justify-content-center">
                <div className="logo align-top">
                    <img src={Logo} alt={t('pageTitle')} />
                </div>
                <div className="deal align-top">  
                    <p>{t('no-program-text')}</p>
                    <a className="button button-purp" target="_blank" href="https://docs.google.com/forms/d/1dRAYv_GGKh5YvzYA0zIk0f43QS6K-SAYchYDn9-Z0Js/">{t('no-program-button')}</a>
                </div>
            </div>
        </div>
    )
}

export default NoProgram;
