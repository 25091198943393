import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import '../css/partners.css';

import KripteusIcon from '../img/kripteus-logo.png';

const Partners = () => {

    const { t } = useTranslation();

    const poweredBy = [{id: 1, logo: KripteusIcon, link: "https://kripteus.com/", name: "Kripteus"}];
    const partners = [{id: 1},{id: 2},{id: 3},{id: 4},{id: 5},{id: 6},{id: 7}];
    const mediaPartners = [{id: 1},{id: 2},{id: 3},{id: 4},{id: 5},{id: 6},{id: 7},{id: 8},{id: 9},{id: 10},{id: 11},{id: 12},{id: 13},{id: 14}];

    return (
        <div id="partners" className="container-fluid partners">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h2>{t('contributor-communities')}</h2>
                    </div>
                    <div className="col-sm-12 logos">
                        <ul>
                            { poweredBy.map((item, index) => (
                                <li key={index}>
                                    <a className="button button-white" href={item.link} target="_blank">
                                        <div className="button-white-inner">
                                            <img src={item.logo} className="crypto-icon" />{item.name}
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* <div className="col-sm-12">
                        <h3>{t('partners')}</h3>
                    </div>
                    <div className="col-sm-12 logos">
                        <ul>
                            { partners.map((item, index) => (
                                <li key={index}><img src="https://loremflickr.com/214/76/budapest" /></li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-sm-12">
                        <h3>{t('media-partners')}</h3>
                    </div>
                    <div className="col-sm-12 logos">
                        <ul>
                            { mediaPartners.map((item, index) => (
                                <li key={index}><img src="https://loremflickr.com/214/76/budapest" /></li>
                            ))}
                        </ul>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Partners;
